<template>
  <div class="edu">
    <div class="linear">
      <div class="search">
        <el-select @enter="onSearch" class="search_item" placeholder="请选择校区" v-model="search.school_id" size="small">
          <el-option :value="0" label="全部校区" :key="0"></el-option>
          <el-option v-for="(item,key) in userInfo.school_arr" :value="key" :label="item" :key="key"></el-option>
        </el-select>
        <el-select @enter="onSearch" class="search_item" placeholder="请选择年级" v-model="search.grade_id" size="small">
          <el-option :value="0" label="全部年级" :key="0"></el-option>
          <el-option v-for="item in gradesData" :value="parseInt(item.id)"  :label="item.name" :key="item.id"></el-option>
        </el-select>
        <el-button class="search_item" size="mini" type="primary" @click="onSearch">查询</el-button>
        <el-button class="search_item" size="mini" type="default" @click="onReset">重置</el-button>
      </div>
      <div class="work_count">
        <div class="base-data" :style="item.color" v-for="(item,index) in count">
          <div class="title">{{item.title}}</div>
          <div class="content">
            <div class="number">{{ item.count }} <span>次</span></div>
            <img :src="$OssAddress+'assets/home-education/'+(index+1)+'.png'" alt="" style="width: 115rem;height: 70rem">
          </div>
        </div>
      </div>
    </div>

	  <div style="width: 100%;height: 14rem;background: #f7f7f7"></div>

    <div class="list">
      <l-table title-bg="#FFF4F1"
               class="data-list1"
               :config="config1"
               :table-data="table1"
               total-color="#F53F3F"
               title="昨日考勤异常汇总"
               :total="total[0]"
               style="width: 48%;min-width: 528rem;"
               @upPage="changePage(0,0)"
               @downPage="changePage(0,1)"
      ></l-table>
      <l-table
          title="昨日一对一辅导"
          class="data-list2"
          :config="config2"
          :table-data="table2"
          :total="total[1]"
          style="width: 48%;min-width: 528rem;"
               @upPage="changePage(1,0)"
               @downPage="changePage(1,1)"
      ></l-table>
    </div>
  </div>
</template>

<script>
import table from "@/components/TableTo"
import {grades} from "@/api/account-management"
import {mapGetters} from "vuex";
export default {
	_config:{"route":{"path":"education-admin","meta":{"title":"首页"}}},
  name: "educationAdmin",
  computed:{
    ...mapGetters(["userInfo"])
  },
  data(){
    return {
      search:{
        school_id:0,
        grade_id:0,
      },
      // 年级数据
      gradesData:[],
      count:[
        {
          title:"昨日教师迟到次数",
          count:0,
          color:"background: linear-gradient(180deg, #F2F9FE 0%, #E6F4FE 100%);"
        },
        {
          title:"昨日教师未打卡次数",
          count:0,
          color:"background: linear-gradient(180deg, #F5FEF2 0%, #E6FEEE 100%);"
        },
        {
          title:"昨日教师代课数",
          count:0,
          color:"background: linear-gradient(180deg, #F2F9FE 0%, #E6F4FE 100%);"
        },
        {
          title:"昨日一对一辅导次数",
          count:0,
          color:"background: linear-gradient(180deg, #F6F7FF 0%, #ECEBFF 100%);"
        },
      ],
      config1:[
        {
          prop:"punch_in_status",
          name:"考勤异常项",
          render:(row)=>{
            let status = row.punch_in_status;
            return status === 0?"未打卡":status === 1?"正常":"迟到"
          }
        },
        {
          prop:"section",
          name:"课节数",
        },
        {
          prop:"teacher_name",
          name:"教师",
        },
      ],
      config2:[
        {
          prop:"subject_name",
          name:"学科",
        },
        {
          prop:"teacher_name",
          name:"教师",
        },
        {
          prop:"student_names",
          name:"学生",
        },
      ],
      table1:[],
      table2:[],
      // 两个表格的页码
      pages:[1,1],
      // 数据量
      total:[0,0]
    }
  },
  mounted() {
    grades().then(res=>{
      this.gradesData = res.data.data;
    })
    this.getData();
  },
  methods:{
    /**
     * @description 当页面内容被更改
     * @param index  pages的下标
     * @param type 0：上一页 1：下一页
     */
    changePage(index,type){
      let pageSize = Math.ceil(this.total[index] / 10);
      let search = this.handleSearch();
      let page = this.pages[index];
      if (type === 0)
        page --;
      else
        page ++;
      if (page <= pageSize && page > 0){
        this.pages[index] = page;
        if (index === 0 ){
          this.getTableData1(search);
        }else{
          this.getTableData2(search);
        }
      }
    },
    // 处理search数据
    handleSearch(){
      let search = Object.assign({},this.search);
      if (!search.grade_id)delete search.grade_id;
      if (!search.school_id)delete search.school_id;
      return search;
    },
    // 获取数据
    getData(){
      let search = this.handleSearch();
      this.$_axios2.get("/api/work/teach-job/job-static",{
        params:{
          ...search
        }
      }).then(res=>{
        let data = res.data;
        if (data.status === 0){
          this.count[0].count = data.data.punch_in_late_num;
          this.count[1].count = data.data.punch_in_not_num;
          this.count[2].count = data.data.agent_num;
          this.count[3].count = data.data.coach_subject_num;
        }else{
          this.$message({
            type:"error",
            message:data.msg,
            showClose:true
          })
        }
      }).catch(err=>{
      });
      this.getTableData1(search);
      this.getTableData2(search);
    },
    // 获取表格1 的数据
    getTableData1(search){
      this.$_axios2.get("/api/work/teach-job/attend-erros",{
        params:{
          ...search,
          page:this.pages[0]
        }
      }).then(res=>{
        let {data} = res;
        if (data.status === 0){
          this.table1 = data.data.list;
          this.total[0] = data.data.page.total;
        }else{
          this.$message({
            type:"error",
            message:data.msg,
            showClose:true
          })
        }
      }).catch(err=>{
      })
    },
    // 获取表格2的数据
    getTableData2(search){
      this.$_axios2.get("/api/work/teach-job/coach-history",{
        params: {
          ...search,
          page:this.pages[1]
        }
      }).then(res=>{
        let {data} = res;
        if (data.status === 0){
          this.table2 = data.data.list;
          this.total[1] = data.data.page.total;
        }else{
          this.$message({
            type:"error",
            message:data.msg,
            showClose:true
          })
        }
      }).catch(err=>{
      })
    },
    // 搜索
    onSearch(){
      this.pages = [1,1];
      this.getData();
    },
    // 重置
    onReset(){
      this.search = {
        school_id:0,
        grade_id:0,
      }
      this.onSearch();
    },
  },
  components:{
    "l-table":table
  }
}
</script>

<style lang="scss" scoped>
.edu{
  //padding:0 ;
  //margin-left: 0 !important;
  //width: 100% !important;
  //background-color: transparent !important;
	background: #fff;
  .linear{
    background-image: linear-gradient(180deg,#FBFBFF 0,#fff 100%);
    .search{
      padding:20rem 25rem 0 25rem;
      .search_item{
        margin-right: 24rem;
        margin-left: 0;
      }
    }
    .work_count{
      display: flex;
      justify-content: space-between;
      padding: 54rem 42rem;
      .base-data{
        min-width: 276rem;
	      width: 23%;
        height: 160rem;
        border-radius: 4rem;
        padding: 16rem 14rem 0 20rem;
        .title{
          font-size: 16rem;
          color: #1D252F;
        }
        .content{
          height: 70rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20rem;
          .number{
            font-size: 26rem ;
            color: #1D2129;
            span{
              color: #999999;
              font-size: 16rem;
              margin-left: 14rem;
            }
          }
        }
      }
    }
  }
  .list{
    padding: 0 42rem;
    margin-top: 42rem;
    display: flex;
    justify-content: space-between;
  }
}
</style>
